import { lazy } from 'react';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';


// render - dashboard
// render - sample page
const Course=Loadable(lazy(() => import('pages/courses/SamplePage')));
const CourseDetail=Loadable(lazy(() => import('pages/courseDetails/SamplePage')));
const Products=Loadable(lazy(() => import('pages/products/SamplePage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Course />
    },
   
   
    {
      path: 'courses',
      element: <Course />
    },
   
   
    {
      path: 'course_detail/:id',
      element: <CourseDetail />
    },
  
    {
      path: 'othercourses',
      element: <Products />
    },
  
  ]
};

export default MainRoutes;
