// assets
import {BookOutlined, CaretRightOutlined,UserOutlined,DashboardOutlined,FileOutlined,MessageOutlined ,PhoneOutlined,InboxOutlined,AppstoreOutlined,AppstoreAddOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  InboxOutlined,
  AppstoreAddOutlined ,
  AppstoreOutlined ,
  MessageOutlined ,
  PhoneOutlined ,
  FileOutlined,
  UserOutlined ,
  CaretRightOutlined,
  BookOutlined 
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  // title: 'Navigation',
  type: 'group',
  children: [
    // {
    //   id: 'dashboard',
    //   title: 'Dashboard',
    //   type: 'item',
    //   url: '/dashboard/default',
    //   icon: icons.DashboardOutlined,
    //   breadcrumbs: false
    // },
   
    {
      id: 'courses',
      title: 'Course',
      type: 'item',
      url: '/courses',
      icon: icons.BookOutlined,
      breadcrumbs: false
    },
    {
      id: 'othercourses',
      title: 'Other Courses',
      type: 'item',
      url: '/othercourses',
      icon: icons.InboxOutlined,
      breadcrumbs: false
    },
    // {
    //   id: 'users',
    //   title: 'Users',
    //   type: 'item',
    //   url: '/users',
    //   icon: icons.UserOutlined,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'videos',
    //   title: 'Videos',
    //   type: 'item',
    //   url: '/videos',
    //   icon: icons.CaretRightOutlined ,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'contact_us',
    //   title: 'Contact us',
    //   type: 'item',
    //   url: '/contact_us',
    //   icon: icons.PhoneOutlined,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'helpAndSupportReq',
    //   title: 'Help & Supports',
    //   type: 'item',
    //   url: '/helpAndSupportReq',
    //   icon: icons.FileOutlined,
    //   breadcrumbs: false
    // },
   
  
  ]
};

export default dashboard;
